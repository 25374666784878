import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { theme } from '../twconfig'

const AnchoredSection = styled.div`
  /* position: relative; */
  /* ${tw`py-16`} */

  /* &::before {
    content: '';
    display: block;
    ${tw`h-20`}
  } */

  /* &:last-child {
    ${tw` -mb-20`}
  } */
`

export default AnchoredSection
