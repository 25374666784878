import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
// import useGatsbyData from '../../hooks/use-gatsby-data'
import 'react-id-swiper/lib/styles/css/swiper.css'
import IdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper, Pagination } from 'swiper/dist/js/swiper.esm.js'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useMediaQueryContext } from '../../context/media'
import tw from 'tailwind.macro'
import viewportMotion from 'components/viewport-motion'
import { motion } from 'framer-motion'
import { SquaresBackgroundSvg } from '../../templates/home'
import SkewBackground from 'components/SkewBackground'
import AnchoredSection from '../AnchoredSection'
import TextTransition from '../text-transition'
import { emptyVariants, motionTransition } from '../../util'
import { theme } from '../../twconfig'
import Container from '../container'

const Post = styled.article`
  .post-image picture img {
    transition: all .5s ease-in-out !important;
  }

  &:hover {
    .post-image picture img {
      transform: scale(1.1);
    }
  }
`

const SwiperSlide = styled.div`
  &.swiper-slide {
    width: 320px;
    
    @media (min-width: ${theme.screens.md}) {
      width: 480px;
    }
  }
`

const swiperConfig = {
  Swiper,
  modules: [Pagination],
  slidesPerView: `auto`,
  spaceBetween: 0,
  centeredSlides: true,
  // loop: true,
  // loopedSlides: 1,
}

const PostsContainer = styled(Container)`
  ${tw`md:mx-10 lg:mx-auto`}

  @media (max-width: ${parseInt(theme.screens.sm) - 1}px) {
    ${tw`px-0`}
  }

  a {
    &:hover, &:focus {
      color: inherit;
    }
  }
`

const postListDesktopVariants = {
  visible: {
    transition: {
      staggerChildren: .3,
    }
  },
  hidden: {}
}

const visibleVariant = { opacity: 1, x: 0, y: 0 }

const postItemDesktopVariants =  {
  visible: visibleVariant,
  hidden: {
    opacity: 0,
    y: 50
  },
}

const postItemDetailsDesktopVariants = {
  visible: visibleVariant,
  hidden: {
    opacity: 0,
    y: -50,
  }
}

export const Blog = ({ className, data, ...props }) => {
  const breakpoints = useMediaQueryContext()

  return (
    (data &&
      (!breakpoints.lg ? (
        <PostsContainer className={className}>
          <BlogSwiper items={data} />
        </PostsContainer>
      ) : (
        <PostsContainer className={className}>
          <BlogDesktop items={data} />
        </PostsContainer>
      ))) ||
    null
  )
}

const BlogDesktop = ({ items, ...props }) => {
  const inViewportOptions = useMemo(() => ({
    threshold: .75,
  }), [])

  return (
    <viewportMotion.ul
      variants={postListDesktopVariants}
      className="posts flex flex-row flex-no-wrap -mx-4"
      inViewportOptions={inViewportOptions}
    >
      {items
        .slice(0, 2)
        .map(
          ({
            id,
            title,
            mainImage: { sharp },
            remark: { excerptLG },
            route,
          }) => {
            return (
              <motion.li
                key={id}
                className="post flex-1 w-1/2 px-4"
                variants={postItemDesktopVariants}
                initial="hidden"
                transition={{ motionTransition, mass: .75 }}
              >
                <Post className="relative rounded overflow-hidden">
                  {sharp && (
                    <Image
                      className="post-image rounded"
                      fluid={sharp.fluid}
                      title={title}
                      style={{ display: `block` }}
                    />
                  )}
                  
                  <motion.div
                    className="bg-black p-5"
                    variants={postItemDetailsDesktopVariants}
                    initial="hidden"
                    transition={{...motionTransition, mass: .5, delay: .25}}
                  >
                    <h1 className="mb-4">
                      <Link className="stretched-link" to={route}>
                        {title}
                      </Link>
                    </h1>
                    <div className="post-excerpt" dangerouslySetInnerHTML={{ __html: excerptLG }} />
                  </motion.div>
                </Post>
              </motion.li>
            )
          }
        )}
    </viewportMotion.ul>
  )
}

const BlogSwiperBase = ({ items = [], className = `` }) => {

  const config = useMemo(() => ({
    ...swiperConfig,
    containerClass: `swiper-container ${className}`,
  }), [className])

  return (
      <IdSwiper {...config}>
        {items.map(({ id, title, mainImage: { sharp }, remark: { excerpt }, route }) => (
          <SwiperSlide key={id}>
            <div className="post relative">
              {sharp && (
                <Image fluid={sharp.fluid} alt={title} className="rounded" style={{ display: `block` }} />
              )}
              
              <div className="p-4">
                <h3 className="mb-4"><Link className="stretched-link" to={route}>{title}</Link></h3>

                <div className="post-excerpt" dangerouslySetInnerHTML={{__html: excerpt}} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </IdSwiper>
  )
}

const BlogSwiper = styled(BlogSwiperBase)`
  .swiper-slide {
    .gatsby-image-wrapper {
      transition: all .3s ease-in-out;
    }

    .post-excerpt {
      opacity: 0;
      transition: all .3s ease-in-out;
    }

    &:not(.swiper-slide-active) {
      .gatsby-image-wrapper {
        transform: scale(.9);
      }
    }
  }

  .swiper-slide-active {
    .post-excerpt {
      opacity: 1;
    }
  }
`

const BlogSection = ({ id, data, ...props }) => (
  <section id={id} data-loadable-fallback={`section-${id}`}>
    <SkewBackground
      className="flex flex-col justify-center py-16 mb-10"
      bgColor="#222"
    >
      <AnchoredSection className="pt-12 pb-0 lg:pb-12">
        <SquaresBackgroundSvg
          fillColor={`black`}
          className="mb-8"
        >
          <h2 className="section-title">
            <viewportMotion.span
              variants={emptyVariants}
              className="inline-block bg-gray-600 px-4"
            >
              <TextTransition text="Dev Diaries" />
            </viewportMotion.span>
          </h2>
        </SquaresBackgroundSvg>

        <Blog data={data} />
      </AnchoredSection>
    </SkewBackground>
  </section>
)

const BlogSectionWithData = ({ data: { allPost: { nodes } }, ...props }) => {
  // const { allPost: { nodes } } = useGatsbyData()

  return (
    <BlogSection {...props} data={nodes} />
  )
}

export default BlogSectionWithData 

export const postArchiveFragment = graphql`
  fragment PostArchive on Post {
    route
      title
      id
      mainImage {
        sharp: childImageSharp {
          fluid(maxWidth: 720, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      remark: childMarkdownRemark {
        excerpt(pruneLength: 64)
        excerptLG: excerpt(pruneLength: 128)
      }
  }
`

export const homeBlogSectionFragment = graphql`
  fragment HomeBlogSectionFragment on Query {
    allPost {
      nodes {
        ...PostArchive
      }
    }
  }
`
